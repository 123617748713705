
import { ref } from "vue";
import PageLayout from "../components/PageLayout.vue";
import { useRoute } from "vue-router";
import { ExternalTool } from "../types/ExternalAcademyTools";
import { OurExternalTools } from "../constants/AcademyTools";
export default {
  name: "Tools",
  components: {
    PageLayout,
  },
  setup() {
    const route = useRoute();
    const internalRoute: string = String(route.params.tool);
    const selectedTool: ExternalTool = OurExternalTools.find(
      (element) => element.internalRoute == internalRoute
    )!;
    let loading = ref(false);
    return {
      loading,
      selectedTool,
    };
  },
  methods: {
    oniFrameLoaded: function () {
      let frame = document.getElementById("iframe") as HTMLIFrameElement;
      frame.style.height =
        frame!.contentWindow!.document.body.scrollHeight + "px";
      let style = document.createElement("style");
      style.textContent = "nav {" + "  display: none;" + "}";
      frame!.contentDocument!.head.appendChild(style);
    },
  },
};
