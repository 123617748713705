
import { reactive } from "vue";
import SecondaryButton from "../components/Button/Secondary.vue";
import { useVuelidate } from "@vuelidate/core";
import {
  email,
  helpers,
  required,
  numeric,
  minLength,
} from "@vuelidate/validators";
import { SendgridEmail } from "@/types/Email";
import SendgridEmailService from "../services/email";

export default {
  components: {
    SecondaryButton,
  },
  props: {
    title: { type: String, default: "Ready to Access this Model?" },
    subtitle: {
      type: String,
      default:
        "Submit your information below to connect with the team and learn more about how to gain access to this model.",
    },
    recipientsEmailAddress: { type: String },
    subject: { type: String },
  },
  setup() {
    const minLen = minLength(10);
    const minLengthWithCustomMsg = helpers.withMessage(
      "Please enter your full phone number w/ area code",
      minLen
    );
    const numericWithCustomMsg = helpers.withMessage(
      "Just numbers please",
      numeric
    );
    const requiredWithCustomMsg = helpers.withMessage(
      "Please fill me in",
      required
    );
    const state = reactive({
      emailAddress: "",
      firmName: "",
      firstName: "",
      lastName: "",
      message: "",
      phoneNumber: "",
      rolePicked: "Individual",
    });
    const rules = {
      emailAddress: { requiredWithCustomMsg, email },
      firstName: { requiredWithCustomMsg },
      lastName: { requiredWithCustomMsg },
      firmName: { requiredWithCustomMsg },
      message: { requiredWithCustomMsg },
      phoneNumber: {
        requiredWithCustomMsg,
        minLengthWithCustomMsg,
        numericWithCustomMsg,
      },
      rolePicked: { requiredWithCustomMsg },
    };
    const v$ = useVuelidate(rules, state);
    return { state, v$ };
  },
  methods: {
    async sendEmailToServer(state, v$) {
      await v$.$validate();
      if (!v$.$error) {
        const formSubmission = `Hello,
        The following is a submission from the Academy.\n
        Name: ${state.firstName} ${state.lastName}
        Firm: ${state.firmName}
        Role: ${state.rolePicked}
        Phone: ${state.phoneNumber}
        Details: ${state.message}
        `;

        const email: SendgridEmail = {
          recipient: state.recipientsEmailAddress,
          subject: state.subject,
          message: formSubmission,
        };

        SendgridEmailService.send(email);
      }
    },
  },
};
