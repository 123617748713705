export const testimonials = [
  {
    logoSrc: require("../assets/testimonial-logo.svg"),
    name: "Simon Tryzna",
    position: "CIO and Financial Advisor",
    twitterHandle: "@ClearpathCapitalPartners",
    content:
      "With cryptoassets emerging as an important asset class, it's essential that advisors have the tools at their disposal to do an appropriate level of due diligence and have the confidence necessary to allocate it to it in a portfolio. Onramp's tools provide me precisely that. I can model out different allocation weights, study various portfolio optimization techniques, and use the results to educate my clients on the benefits of a cryptoasset allocation.",
  },
  {
    logoSrc: require("../assets/social-twitter.svg"),
    name: "Josh Womack",
    position: "Financial Advisor",
    twitterHandle: "@JoshWomack",
    content:
      "For early stage companies focused on helping advisors serve clients, none do it better than @ Onrampinvest. Doesn't matter what you think of crypto, the thought, passion, focus on education and pushing technology forward ... they are doing it.",
  },
  {
    logoSrc: require("../assets/social-twitter.svg"),
    name: "Matt Fizell",
    position: "CFP – Financial Advisor",
    twitterHandle: "@MattFizell",
    content:
      "If you’re a new financial planner and want a quick deep dive into the world of Crypto, I couldn’t recommend @Onrampinvest‘s Advisor Academy any higher. I’m sure they’ll add more over time, but it saved me a ton of google search time for the foundations of Crypto!",
  },
  {
    logoSrc: require("../assets/social-twitter.svg"),
    name: "Nassim Olive",
    position: "Partner at Eterna Capital",
    twitterHandle: "@NassimOlive",
    content:
      "Historically, even when done with conservative position sizing, #bitcoin allocations have still had a meaningful impact on #portfolio returns.” Thanks to amazing material built by @OnrampAcademy and @Onrampinvest, RIAs have all the necessary tools to adjust portfolio composition.",
  },
  {
    logoSrc: require("../assets/social-twitter.svg"),
    name: "Vincent Barbera",
    position: "CFP",
    twitterHandle: "@vrbst4",
    content:
      "I’m subscribed to the Academy and I love it. Already shared pieces w client. Can’t wait to see what else is in store!",
  },
];

//Lets add our default search payload here so that we can have no api calls, and instant'er results
