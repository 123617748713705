
import { defineComponent, computed } from "vue";

export default defineComponent({
  props: {
    width: { type: String, default: () => `100%` },
    height: { type: String, default: () => `100%` },
    backgroundColor: { type: String, default: () => `var(--color__light` },
    withShadow: { type: Boolean, default: () => false },
    borderSizeRadius: { type: String, default: () => `40px` },
    borderColor: { type: String, default: () => `var(--color__dark` },
    isClosable: { type: Boolean, default: () => false },
    outlined: { type: Boolean, default: () => false },
    maxWidth: { type: String, default: () => "" },
  },
  setup(props) {
    const customCss = computed(() => {
      return {
        width: props.width,
        height: props.height,
        "max-width": props.maxWidth,
        "border-radius": props.borderSizeRadius,
        "box-shadow": props.withShadow ? "4px 2px rgba(0,0,0, 0.08)" : "none",
        "background-color": props.backgroundColor,
        border: props.outlined ? `2px solid ${props.borderColor}` : "none",
      };
    });
    return {
      customCss,
    };
  },
});
