import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(_ctx.customClasses),
    style: _normalizeStyle(_ctx.customCss),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.emitButtonClick && _ctx.emitButtonClick(...args)))
  }, _toDisplayString(_ctx.text), 7))
}