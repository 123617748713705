<template>
  <PageLayout
    :loading="loading"
    :background-img-type="'primary'"
    :use-title-section="true"
  >
    <template #header>
      <div class="d-flex flex-column align-items-center my-5">
        <div>
          <Header :text="'Content Library'" />
        </div>
        <div class="p-2 px-5">
          <p></p>
          <p class="text-center">
            Check out the new and featured items in the carousel, or browse our
            resources below to get started!
          </p>
          <p class="text-center">
            Onramp Academy is filled with industry-leading market research,
            resources on foundational cryptoasset knowledge, models from trusted
            partners, and powerful tools to test out various investment
            strategies. This library is specifically curated to help financial
            advisors navigate the emerging digital asset economy.
          </p>
        </div>
      </div>
    </template>
    <section class="featured-slider pb-3">
      <div class="container">
        <div class="row">
          <div class="col">
            <FeaturedSlider :content="bannerContent" />
          </div>
        </div>
      </div>
    </section>
    <section class="pb-3">
      <div class="container">
        <FilterGridWithSearch
          :data="gridContent"
          :initialNumberOfTilesShown="24"
          :filterGroups="filterGroups"
          :filters="filterSet"
        />
      </div>
    </section>
  </PageLayout>
</template>
<script>
import {
  onBeforeMount,
  ref,
  reactive,
  onMounted,
  inject,
  getCurrentInstance,
} from "vue";
import ButtonWithIcon from "../components/Button/Icon.vue";
import WhitelabelService from "../services/whitelabel";
import ContentService from "../services/content";
import FeaturedSlider from "../components/FeaturedSlider.vue";
import FilterGridWithSearch from "../components/FilterGridWithSearch.vue";
import Header from "../components/Typographical/Header.vue";
import Label from "../components/Typographical/Label.vue";
import PageLayout from "../components/PageLayout.vue";
import { useStore } from "vuex";
import WhiteLabelTheme from "@/types/WhiteLabel";
export default {
  name: "ContentLibrary",
  components: {
    FeaturedSlider,
    FilterGridWithSearch,
    Header,
    PageLayout,
  },
  setup() {
    const kc = inject("$kc");
    let bannerContent = ref();
    let gridContent = ref();
    let filterSet = ref();
    let filterGroups = [];
    let loading = ref(true);
    onBeforeMount(async () => {
      try {
        //We send all three requests at once in parallel,
        //We then collect their responses asynchronously.
        //We now have an execution time of just the
        //slowest fetch, rather than of the sum of all fetches.
        const griddataPromise = ContentService.getAllEntriesForPreview();
        const bannerdataPromise = ContentService.getFeaturedEntries();
        const filterSetPromise = ContentService.getFilterCategories();
        const griddata = await griddataPromise;
        const bannerdata = await bannerdataPromise;
        const filterdata = await filterSetPromise;
        gridContent.value = griddata.contentLibrary;
        bannerContent.value = bannerdata.contentLibrary;
        filterSet.value = filterdata;
        for (const filterCategoryGroup in filterSet.value) {
          filterGroups.push(filterCategoryGroup);
        }
        loading.value = false;
      } catch (e) {
        console.log("error" + e);
      }
    });
    onMounted(async () => {
      //Fetch info needed for Whitelabeling Vertification

      const instance = getCurrentInstance();
      const usersOrganizationID = kc.idTokenParsed.oroid;
      const whiteLableledPartners =
        WhitelabelService.getWhiteLabeledParternerIDs();
      if (whiteLableledPartners.includes(usersOrganizationID)) {
        //Fetch info needed for whitelabelelbing
        const whitelabelData =
          await WhitelabelService.getWhiteLabeledParterners();
        const partnersThemeData = whitelabelData.data.whitelabelPartners.find(
          (p) => p.organizationID == usersOrganizationID
        );
        let partnersTheme = {
          organizationID: partnersThemeData.organizationID,
          primaryColor: partnersThemeData.primaryColor,
          secondaryColor: partnersThemeData.secondaryColor,
          primaryLogoUrl: partnersThemeData.primaryLogo.url,
        };
        var bodyStyles = document.body.style;
        var logo = document.getElementById("top_logo");
        //Apply Their Theme
        WhitelabelService.updateCurrentTheme(bodyStyles, partnersTheme, logo);
      }
    });

    return {
      gridContent,
      bannerContent,
      loading,
      filterGroups,
      filterSet,
    };
  },
};
</script>
