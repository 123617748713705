
import { Options, Vue } from "vue-class-component";

import Card from "../components/Card.vue";

@Options({
  props: {
    title: String,
    body: String,
  },
  components: {
    Card,
  },
})
export default class CardHomePageFeature extends Vue {
  title!: string;
  body!: string;
}
