import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createBlock(_component_Button, {
    "min-width": '151px',
    "background-color": 'var(--color__secondary)',
    textColor: "white",
    text: _ctx.text,
    height: '56px',
    customClasses: "base-button rounded-0 px-1 mx-1 w-75"
  }, null, 8, ["background-color", "text"]))
}