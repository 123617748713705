
import { defineComponent, computed } from "vue";
import Button from "./Base.vue";

export default defineComponent({
  components: {
    Button,
  },
  props: {
    text: { type: String, default: "" },
    disabled: { type: Boolean, default: false },
    height: { type: String, default: "56px" },
    minWidth: { type: String, default: "" },
    width: { type: String, default: "100%" },
  },
  setup() {
    const icon = computed(() => {
      return {
        "--icon": `url(${require("@/assets/filter-icon.svg")})`,
      };
    });

    return {
      icon,
    };
  },
});
