import exportFromJSON from "export-from-json";
import type { AssetAllocation } from "@/types/GraphCMS";
export default class FileDownload {
  //Takes an assetAllocation list and exports a file.
  public static modelLibraryDownload(
    assetAllocations: [AssetAllocation],
    modelName: String,
    exportType = exportFromJSON.types.csv
  ) {
    const fileName = "AcademyAssetAllocation_" + modelName;
    exportFromJSON({
      data: assetAllocations,
      fileName: fileName,
      exportType: exportType,
    });
  }

  public static downloadFactcards(
    data: Object,
    exportType = exportFromJSON.types.csv
  ) {
    const fileName = "Onramp_Factcards";
    exportFromJSON({
      data: data,
      fileName: fileName,
      exportType: exportType,
    });
  }
}
