
import { defineComponent, PropType } from "vue";
import Card from "./Card.vue";
import Testimonial from "../types/Testimonial";
export default defineComponent({
  components: {
    Card,
  },
  props: {
    testimonial: {
      required: true,
      type: Object as PropType<Testimonial>,
    },
  },
});
