
import { defineComponent, computed } from "vue";

export default defineComponent({
  props: {
    text: { type: String, default: "" },
    color: { type: String, default: "var(--color__grayscale--deep)" },
    alignment: { type: String, default: "center" },
    cursor: { type: String, default: "auto" },
    weight: { type: String, default: "normal" },
  },
  setup(props) {
    const customCss = computed(() => {
      return {
        "--color": props.color,
        "--alignment": props.alignment,
        "--cursor": props.cursor,
        "--weight": props.weight,
      };
    });

    return {
      customCss,
    };
  },
});
