import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import ContentLibraryDetailView from "../views/ContentLibraryDetailView.vue";
import LandingPage from "../views/LandingPage.vue";
import LoginCallback from "../views/LoginCallBack.vue";
import ContentLibrary from "../views/ContentLibrary.vue";
import ModelLibrary from "../views/ModelLibrary.vue";
import ModelLibraryDetailView from "../views/ModelLibraryDetailView.vue";
import AcademyTools from "../views/AcademyTools.vue";
import FactCards from "../views/FactCards.vue";
import { getEnvironmentVariable } from "@/utils/environment-variables";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/home",
    name: "Home",
    component: LandingPage,
  },
  {
    path: "/content-library",
    name: "Content-Library",
    component: ContentLibrary,
    meta: { requiresAuth: true },
  },
  {
    path: "/content-library/:slug",
    name: "ContentLibraryDetailView",
    component: ContentLibraryDetailView,
    meta: { requiresAuth: true },
  },
  {
    path: "/model-library",
    name: "Model-Library",
    component: ModelLibrary,
    meta: { requiresAuth: true },
  },
  {
    path: "/model-library/:slug",
    name: "ModelLibraryDetailView",
    component: ModelLibraryDetailView,
    meta: { requiresAuth: false },
  },
  {
    path: "/tools/:tool",
    name: "AcademyTools",
    component: AcademyTools,
    meta: { requiresAuth: true },
  },
  {
    path: "/fact-cards",
    name: "FactCards",
    component: FactCards,
    meta: { requiresAuth: true },
  },
];

const router = createRouter({
  history: createWebHistory(getEnvironmentVariable("BASE_URL")),
  routes,
});



export default router;
