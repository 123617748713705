import { getEnvironmentVariable } from "../utils/environment-variables";
//Env Variables
export const ERROR_MONITORING_COMMIT_SHA = getEnvironmentVariable(
  "VUE_APP_ERROR_MONITORING_COMMIT_SHA"
);
export const ERROR_MONITORING_ENABLED =
  getEnvironmentVariable("VUE_APP_ERROR_MONITORING_ENABLED") === "1";
export const ERROR_MONITORING_ENVIRONMENT = getEnvironmentVariable(
  "VUE_APP_ERROR_MONITORING_ENVIRONMENT"
);
export const SENTRY_DSN = getEnvironmentVariable("VUE_APP_SENTRY_DSN");
export const USER_INACTIVITY_LIMIT = getEnvironmentVariable(
  "VUE_APP_USER_INACTIVITY_LIMIT"
);
export const USERS_LOGOUT_MODAL_COUNTDOWN_INITAL_VALUE = getEnvironmentVariable(
  "VUE_APP_USERS_LOGOUT_MODAL_COUNTDOWN_INITAL_VALUE"
);
export const VUE_APP_ACADEMY_REGISTRATION_LINK = getEnvironmentVariable(
  "VUE_APP_ACADEMY_REGISTRATION_LINK"
);
export const VUE_APP_LOGOUT_REDIRECT_URI = getEnvironmentVariable(
  "VUE_APP_LOGOUT_REDIRECT_URI"
);
export const VUE_APP_OKTA_CLIENT_ID = getEnvironmentVariable(
  "VUE_APP_OKTA_CLIENT_ID"
);
export const VUE_APP_OKTA_ISSUER = getEnvironmentVariable(
  "VUE_APP_OKTA_ISSUER"
);
export const VUE_APP_OKTA_REDIRECT_URI = getEnvironmentVariable(
  "VUE_APP_OKTA_REDIRECT_URI"
);
export const VUE_APP_MIXPANEL_ACADEMY_PROJECT_TOKEN = getEnvironmentVariable(
  "VUE_APP_MIXPANEL_ACADEMY_PROJECT_TOKEN"
);


export const VUE_APP_KEYCLOAK_URL = getEnvironmentVariable("VUE_APP_KEYCLOAK_URL")
export const VUE_APP_KEYCLOAK_REALM = getEnvironmentVariable("VUE_APP_KEYCLOAK_REALM")
export const VUE_APP_KEYCLOAK_CLIENT_ID = getEnvironmentVariable("VUE_APP_KEYCLOAK_CLIENT_ID")

export const VUE_APP_KEYCLOAK_REDIRECT = getEnvironmentVariable("VUE_APP_KEYCLOAK_REDIRECT")
