
import { defineComponent, computed, inject, toRef } from "vue";
import Footer from "./Footer.vue";
import AuthNavigation from "./NavigationBar.vue";
import PublicNavigation from "./NavigationBarLoginSignup.vue";
import Loader from "./Loading.vue";
import { useBackgroundService } from "../services/backgrounds-service";
export default defineComponent({
  components: {
    Footer,
    AuthNavigation,
    PublicNavigation,
    Loader,
  },
  props: {
    backgroundColor: { type: String, default: "transparent" },
    contentsHeight: { type: String, default: "inherit" },
    useTitleSection: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    /**
     * backgroundImgType "public"  : dark ovals [ex]: home page
     * bakgroundImgType  "primary" : light ovals [ex]: content library
     */
    backgroundImgType: { type: String, default: "" },
    headerTextColor: { type: String, default: "var(--color__secondary)" },
    navbarDarkMode: { type: Boolean, defult: false },
    useSecondHeader: { type: Boolean, default: false },
    secondSectionBackground: { type: String, default: "" },
  },
  setup(props) {
    const kc = inject("$kc");
    const isLoading = toRef(props, "loading");

    const authState = computed(() => {
      return kc;
    });
    const customCss = computed(() => {
      /**
       * @returns : page layout dynamic CSS and background image
       *            for the navigation and top section
       */
      const backgroundCss = useBackgroundService(props.backgroundImgType);
      return {
        "--content-body-height": props.contentsHeight,
        "--background-color": props.backgroundColor,
        "--headerTextColor": props.headerTextColor,
        ...backgroundCss,
      };
    });
    const secondSectionCustomCss = computed(() => {
      /**
       * @returns : page layout dynamic CSS and background image
       *            for the navigation and top section
       */
      const secondSecondCss = useBackgroundService(
        props.secondSectionBackground
      );
      return {
        "--content-body-height": props.contentsHeight,
        "--background-color": props.backgroundColor,
        "--headerTextColor": props.headerTextColor,
        ...secondSecondCss,
      };
    });
    return {
      authState,
      customCss,
      isLoading,
      secondSectionCustomCss,
    };
  },
});
