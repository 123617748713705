
import { defineComponent, PropType, toRef, ref, computed } from "vue";
import { GridTypes } from "../types/GraphCMS";
import { ResponseTypes, Preview } from "../types/GraphCMS";
import { useRouter } from "vue-router";
import { getEnvironmentVariable } from "@/utils/environment-variables";

export default defineComponent({
    name: "ModelTable",
    props: {
        content: {
            type: Array as PropType<ResponseTypes[]>,
            default: () => [],
        },
        routeTo: {
            type: String as PropType<GridTypes>,
            default: GridTypes.ContentLibrary,
        },
        onInitShowThisManyItems: { type: Number, default: 3 },
        outlineMyItems: { type: Boolean, default: true },
        hideLoadMoreBtnWhenBtnIsDisabled: { type: Boolean, default: false },
    },
    data() {
        return {
            baseUrl: getEnvironmentVariable(
                "VUE_APP_ACADEMY_BACKEND_SERVICE_URI"
            ),
            apiUrl: `${getEnvironmentVariable(
                "VUE_APP_ACADEMY_BACKEND_SERVICE_URI"
            )}/document/model-marketplace`,
        };
    },
    setup(props) {
        return {};
    },
});
