
import { defineComponent } from "vue";

const LEARN_MORE_LINK = "https://onrampinvest.com/academy";

export default defineComponent({
  components: {},
  props: {
    imgSource: String,
    line1: String,
    line2: String,
  },
  methods: {
    goToAcademyTab() {
      window.open(LEARN_MORE_LINK);
    },
  },
});
