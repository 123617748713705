<template>
  <div class="overlay">
    <div class="container main-wrapper">
      <div class="row main-wrapper align-items-center">
        <div class="col"></div>
        <div class="col text-center align-items-center">
          <Card width="400px" height="286px" with-shadow="true">
            <div class="container main-wrapper position-relative">
              <div class="row py-3 position-absolute top-0 full-width">
                <div class="col p-2 text-center">
                  <h2>Inactivity Detected</h2>
                  <hr class="hr" />
                </div>
              </div>
              <div class="row py-3 position-absolute bottom-0 full-width">
                <div class="col p-2 text-center">
                  <h4>Logging out in: {{ second }}s</h4>
                </div>
              </div>
            </div>
          </Card>
        </div>
        <div class="col"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "../components/Card.vue";
import * as Constants from "@/constants/env.js";
import { inject } from "vue";
export default {
  data() {
    return {
      time: Constants.USERS_LOGOUT_MODAL_COUNTDOWN_INITAL_VALUE,
    };
  },
  computed: {
    second() {
      return this.time / 1000;
    },
  },
  components: {
    Card,
  },
  setup() {
    const kc = inject("$kc");
    return {
      authState: kc
    }
  },
  methods: {
    async logout() {
      // Read idToken before local session is cleared
      // Clear local session
      await this.authState.logout();
      sessionStorage.clear();
    },
  },
  created() {
    let timerId = setInterval(() => {
      this.time -= 1000;
      if (!this.isAppIdle) clearInterval(timerId);
      if (this.time < 1) {
        clearInterval(timerId);
        this.logout();
      }
    }, 1000);
  },
};
</script>
<style lang="scss" scoped>
.hr {
  margin-left: -32px;
  margin-right: -32px;
}
.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 100;
}
.main-wrapper {
  height: 100vh;
}
.full-width {
  width: 92%;
}
</style>
