<template>
  <ModalLogout v-if="isIdle" />
  <router-view :key="$route.fullPath" />
</template>

<script>
import { computed } from "vue";
import ModalLogout from "@/components/ModalLogout.vue";
import { VUE_APP_MIXPANEL_ACADEMY_PROJECT_TOKEN} from "@/constants/env";

mixpanel.init(VUE_APP_MIXPANEL_ACADEMY_PROJECT_TOKEN, { debug: false, track_pageview: true, persistence: 'localStorage' });

export default {
  name: "App",
  components: {
    ModalLogout,
  },
  computed: {
    isIdle() {
      return this.isAppIdle;
    },
  },
};
</script>
<style lang="scss">
@import "@/styles/index.scss";
@import "@/assets/fonts/_typography.scss";
</style>
