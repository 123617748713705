import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3eac9b56"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container gradiant-blue-bg" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-4" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "col-8 slider-text mt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("img", {
          src: _ctx.imgSource,
          class: "image-bg float-left"
        }, null, 8, _hoisted_4)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("h4", null, _toDisplayString(_ctx.line1), 1)
      ])
    ])
  ]))
}