
import { ref, defineComponent } from "vue";
import Carousel from "../components/Carousel.vue";
import CardHomePageFeature from "../components/CardHomePageFeature.vue";
import CardHomePageLearnMore from "../components/CardHomePageLearnMore.vue";
import PageLayout from "../components/PageLayout.vue";

const VIDEO_SRC =
  "https://player.vimeo.com/video/596122658?h=7bd6772d67&dnt=1&app_id=122963&autoplay=1";

export default defineComponent( {
  name: "LandingPage",
  components: {
    Carousel,
    CardHomePageFeature,
    CardHomePageLearnMore,
    PageLayout,
  },
  setup() {
    const source = ref(VIDEO_SRC);

    return {
      source,
    };
  },
});
