import { ExternalTool, ToolName } from "../types/ExternalAcademyTools";

export const OurExternalTools: ExternalTool[] = [
  {
    name: ToolName.The6040Dashboard,
    externalSource: "https://academy-tools.onrampinvest.com/apps/dashboard",
    internalRoute: "the-6040-dashboard",
    desktopHeight: 1386,
  },
  {
    name: ToolName.CustomStrategyDashboard,
    externalSource:
      "https://academy-tools.onrampinvest.com/apps/custom-dashboard",
    internalRoute: "custom-strategy-dashboard",
    desktopHeight: 2448,
  },
  {
    name: ToolName.PortfolioOptimizer,
    externalSource:
      "https://academy-tools.onrampinvest.com/apps/portfolio-optimizer",
    internalRoute: "portfolio-optimizer",
    desktopHeight: 2000,
  },
  {
    name: ToolName.CorrelationCharts,
    externalSource:
      "https://academy-tools.onrampinvest.com/apps/correlation-matrix",
    internalRoute: "correlation-charts",
    desktopHeight: 1700,
  },
  {
    name: ToolName.AssetQuilt,
    externalSource: "https://academy-tools.onrampinvest.com/apps/asset-returns",
    internalRoute: "asset-quilt",
    desktopHeight: 1440,
  },
  {
    name: ToolName.CryptocurrencyQuilt,
    externalSource:
      "https://academy-tools.onrampinvest.com/apps/crypto-returns",
    internalRoute: "cryptocurrency-quilt",
    desktopHeight: 1440,
  },
  {
    name: ToolName.RollingVolatilityCharts,
    externalSource:
      "https://academy-tools.onrampinvest.com/apps/volatility-chart",
    internalRoute: "rolling-volatility-charts",
    desktopHeight: 1440,
  },
  {
    name: ToolName.AnnualizedVolatility,
    externalSource:
      "https://academy-tools.onrampinvest.com/apps/annualized-volatility",
    internalRoute: "annualized-volatility",
    desktopHeight: 2448,
  },
];
