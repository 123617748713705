import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createBlock(_component_Button, {
    "min-width": _ctx.width,
    height: _ctx.height,
    "background-color": 'var(--color__secondary)',
    "text-color": 'var(--color__light)',
    text: _ctx.text,
    disabled: _ctx.disabled,
    outlined: _ctx.outlined
  }, null, 8, ["min-width", "height", "background-color", "text-color", "text", "disabled", "outlined"]))
}