import {createApp} from "vue";
import App from "./App.vue";
import router from "./router/router";
import * as Sentry from "@sentry/vue";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import IdleVue from "idle-vue";
import Vuex from "vuex";
import * as Constants from "./constants/env.js";
import Keycloak from 'keycloak-js';
import {VUE_APP_KEYCLOAK_REDIRECT} from "./constants/env.js";

const store = new Vuex.Store({
    // ...
});


const app = createApp(App);

app.use(router).use(IdleVue, {
    idleTime: Constants.USER_INACTIVITY_LIMIT,
    store,
    startAtIdle: false,
});


const initOptions = {
    url: Constants.VUE_APP_KEYCLOAK_URL,
    realm: Constants.VUE_APP_KEYCLOAK_REALM,
    clientId: Constants.VUE_APP_KEYCLOAK_CLIENT_ID,
}

const keycloak = new Keycloak(initOptions);

async function initAuth() {
    try {
        const authenticated = await keycloak.init({
            onLoad: 'check-sso',
            pkceMethod: 'S256',
            redirectUri: VUE_APP_KEYCLOAK_REDIRECT
        });
        return keycloak;
    } catch (error) {
        console.error('Keycloak failed to initialize', error)
    }
}

if (Constants.ERROR_MONITORING_ENABLED) {
    Sentry.init({
        app,
        dsn: Constants.SENTRY_DSN,
        release: Constants.ERROR_MONITORING_COMMIT_SHA,
        environment: Constants.ERROR_MONITORING_ENVIRONMENT,
        integrations: [],
        tracesSampleRate: 0,
    });
}

initAuth().then((kc) => {
    app.provide("$kc", kc);
    app.mount("#app");
}).catch((error) => {
    console.error('Keycloak js failed to initialize', error)
})


