
import { defineComponent, PropType, toRef, ref, computed } from "vue";
import { GridTypes } from "../types/GraphCMS";
import { ResponseTypes, Preview } from "../types/GraphCMS";
import { useRouter } from "vue-router";
import Button from "./Button/Secondary.vue";
import Card from "./Card.vue";

export default defineComponent({
  name: "FilterGrid",
  components: {
    Card,
    Button,
  },
  props: {
    content: { type: Array as PropType<ResponseTypes[]>, default: () => [] },
    routeTo: {
      type: String as PropType<GridTypes>,
      default: GridTypes.ContentLibrary,
    },
    onInitShowThisManyItems: { type: Number, default: 3 },
    outlineMyItems: { type: Boolean, default: true },
    hideLoadMoreBtnWhenBtnIsDisabled: { type: Boolean, default: false },
  },
  setup(props) {
    const content = toRef(props, "content");
    const displayedSlice = ref(props.onInitShowThisManyItems);
    const router = useRouter();

    const loadMore = () => {
      displayedSlice.value =
        displayedSlice.value + props.onInitShowThisManyItems;
    };
    let routeToIndividualPage;
    switch (props.routeTo) {
      case GridTypes.ContentLibrary:
        routeToIndividualPage = (entry: Preview) => {
          router.push({
            name: "ContentLibraryDetailView",
            params: {
              slug: entry.slug,
            },
          });
        };
        break;
      case GridTypes.ModelLibrary:
        routeToIndividualPage = (entry: Preview) => {
          router.push({
            name: "ModelLibraryDetailView",
            params: {
              slug: entry.slug,
            },
          });
        };
        break;
      case GridTypes.ModelLibaryDetailView:
        routeToIndividualPage = (entry: Preview) => {
          router.push({
            name: "ModelLibraryDetailView",
            params: {
              slug: entry.slug,
            },
          });
        };
        break;
      default:
        console.log("Error");
    }

    const disableLoadMoreButton = computed(() => {
      return displayedSlice.value >= content.value.length;
    });

    return {
      loadMore,
      displayedSlice,
      disableLoadMoreButton,
      routeToIndividualPage,
    };
  },
});
