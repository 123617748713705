import { reactive } from "vue";

const MAP_BACKGROUND_TYPE_TO_IMAGE = Object.freeze({
  // Home Page
  public: require("@/assets/background-ovals-trans.png"),
  // Content Library, Model Library, and Fact Cards
  primary: require("@/assets/background-ovals-white.svg"),
  // model library
  modelLibrary: require("@/assets/banner-bg-star.jpg"),
  modelLibarySecondSection: require("@/assets/background-model-libary-section-2.jpeg"),

  modelLibraryDetailView: require("@/assets/background-white-with-grey-dots.png"),
});

export const useBackgroundService = (backgroundType: String) => {
  /**
   * @param backgroundType : key of MAP_BACKGROUND_TO_IMAGE
   * @returns Object for CSS variables
   */
  const backgroundTypeLookup =
    backgroundType as keyof typeof MAP_BACKGROUND_TYPE_TO_IMAGE;

  const backgroundCss = reactive({
    "--background-image": "none",
  });

  if (MAP_BACKGROUND_TYPE_TO_IMAGE[backgroundTypeLookup]) {
    backgroundCss[
      "--background-image"
    ] = `url(${MAP_BACKGROUND_TYPE_TO_IMAGE[backgroundTypeLookup]})`;
  }

  return backgroundCss;
};
