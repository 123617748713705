export type ExternalTool = {
  name: ToolName;
  externalSource: string;
  internalRoute: string;
  desktopHeight: number;
};

export enum ToolName {
  The6040Dashboard = "60/40 Dashboard",
  CustomStrategyDashboard = "Custom Strategy Dashboard",
  PortfolioOptimizer = "Portfolio Optimizer",
  CorrelationCharts = "Correlation Charts",
  AssetQuilt = "Asset Quilt",
  CryptocurrencyQuilt = "Cryptocurrency Quilt",
  RollingVolatilityCharts = "Rolling Volatility Charts",
  AnnualizedVolatility = "Annualized Volatility",
}
