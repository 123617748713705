
import { defineComponent, ref } from "vue";
import CardTestimonial from "./CardTestimonial.vue";
import { testimonials } from "../constants/Testimonials";
import Testimonial from "../types/Testimonial";

export default defineComponent({
  components: {
    CardTestimonial,
  },
  props: {
    itemsPerSlide: { required: true, type: Number },
  },
  setup(props) {
    const numberOfSlides = ref(testimonials.length % props.itemsPerSlide);
    const inactiveItems = ref(testimonials.length - props.itemsPerSlide);

    const firstSetCutOff = ref(props.itemsPerSlide - 1);
    const testimonialList = ref<Testimonial[]>(testimonials);

    return {
      testimonialList,
      numberOfSlides,
      firstSetCutOff,
      inactiveItems,
    };
  },
  mounted() {},
});
