import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "container pt-2 tools-app" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col text-center" }
const _hoisted_4 = ["src", "height"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageLayout = _resolveComponent("PageLayout")!

  return (_openBlock(), _createBlock(_component_PageLayout, {
    loading: $setup.loading,
    "background-img-type": 'modelLibrary',
    "use-title-section": false,
    "header-text-color": 'var(--color__light)',
    "navbar-dark-mode": true
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("iframe", {
              id: "iframe",
              src: $setup.selectedTool.externalSource,
              scrolling: "no",
              style: {"border":"none"},
              width: "100%",
              height: $setup.selectedTool.desktopHeight,
              onLoad: _cache[0] || (_cache[0] = ($event: any) => ($options.oniFrameLoaded()))
            }, null, 40, _hoisted_4)
          ])
        ])
      ])
    ]),
    _: 1
  }, 8, ["loading", "header-text-color"]))
}