
import Header from "../components/Typographical/Header.vue";
import Label from "../components/Typographical/Label.vue";
import PageLayout from "../components/PageLayout.vue";
import ContentService from "../services/content";
import Card from "../components/Card.vue";
import Table from "../components/Table.vue";
import { ref, onBeforeMount } from "vue";

export default {
  name: "FactCards",
  components: {
    Card,
    PageLayout,
    Header,
    Label,
    Table,
  },
  setup() {
    const headers = [
      "RANK",
      "",
      "NAME",
      "SYMBOL",
      "PRICE",
      "MARKET CAP",
      "1H %",
      "24H %",
      "7D %",
      "30D %",
      "",
    ];
    let content = ref();
    let loading = ref(true);
    onBeforeMount(async () => {
      try {
        const data = await ContentService.getFactCards();
        content.value = data;
        loading.value = false;
      } catch (e) {
        console.log("error" + e);
      }
    });
    let smallPriceFormatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      maximumFractionDigits: 7,
    });
    let priceFormatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      maximumFractionDigits: 2,
    });
    let marketCapFormatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      maximumFractionDigits: 0,
    });
    let percentageFormatter = new Intl.NumberFormat("en-US", {
      style: "percent",
      maximumFractionDigits: 4,
      signDisplay: "always",
    });
    //For certain columns, I make the cell prettier by adding currency or math decorations.
    const prettyPrint = (col, value) => {
      if (col == "price") {
        return value > 10
          ? priceFormatter.format(value)
          : smallPriceFormatter.format(value);
      }
      if (col == "market_cap") {
        return marketCapFormatter.format(value);
      }
      if (
        col == "change_over_1h" ||
        col == "change_over_24h" ||
        col == "change_over_7d" ||
        col == "change_over_30d"
      ) {
        return percentageFormatter.format(value / 100);
      }
      if (col == "pdf_url") {
        if (value != null) return "<a href='" + value + "'>Pdf</a>";
        return "";
      }
      if (col == "cmc_id") {
        if (value != null)
          return (
            "<img width=25 src='https://s2.coinmarketcap.com/static/img/coins/64x64/" +
            value +
            ".png'></img>"
          );
        return "";
      }
      return value;
    };

    return {
      content,
      loading,
      headers,
      prettyPrint,
    };
  },
};
