import axios, { AxiosResponse, AxiosError } from "axios";
import { SendgridEmail } from "@/types/Email";
import { getEnvironmentVariable } from "@/utils/environment-variables";

const baseApiUrl = getEnvironmentVariable(
  "VUE_APP_ACADEMY_BACKEND_SERVICE_URI"
);
const apiUrl = `${baseApiUrl}/emailer`;

export default class SendgridEmailService {
  public static async send(Payload: SendgridEmail): Promise<String> {
    return await axios
      .post(`${apiUrl}/send`, Payload)
      .then((res: AxiosResponse): String => {
        return res.data;
      })
      .catch((err: AxiosError): String => {
        return err.message;
      });
  }
}
