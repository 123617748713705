<template>
  <PageLayout
    v-if="content"
    :background-img-type="'modelLibrary'"
    :use-title-section="true"
    :header-text-color="'#FFFFFF'"
    :navbar-dark-mode="false"
    :use-second-header="false"
    :second-section-background="'modelLibarySecondSection'"
  >
    <template #header>
      <div class="container">
        <div class="row align-items-center first-section">
          <div class="align-items-center col-6 text-left my-5">
            <h1>Onramp Marketplace</h1>
            <br />
            <p>
              Our model marketplace brings you curated investment opportunities 
              built on the research, market intelligence, and tested strategies of 
              some of the most trusted and innovative names in the industry. 
              Here you'll find a range of digital asset strategies that provide access 
              and simplicity that can’t be found elsewhere, brought to you all in one place.
            </p>
          </div>
        </div>
      </div>
    </template>
    <div class="container model-library-grid">
      <div class="row">
        <div class="col text-center">
          <h1>Browse Models</h1>
        </div>
      </div>
      <div class="row">
        <div class="col text-center my-3">
          <ModelTable
            :content="content"
            :route-to="'ModelLibrary'"
            :on-init-show-this-many-items="1000"
            :outline-my-items="false"
            :hide-load-more-btn-when-btn-is-disabled="true"
          />
        </div>
      </div>
    </div>
  </PageLayout>
</template>

<script>
import { ref, onBeforeMount, onMounted, getCurrentInstance, inject } from "vue";
import PageLayout from "../components/PageLayout.vue";
import ModelTable from "../components/ModelTable.vue";
import ContentService from "../services/content";
import Card from "../components/Card.vue";
import { GridTypes } from "../types/GraphCMS";
import WhiteLabelTheme from "@/types/WhiteLabel";
import WhitelabelService from "../services/whitelabel";

export default {
  name: "ModelLibrary",
  components: {
    PageLayout,
    ModelTable,
  },
  setup() {
    const kc = inject("$kc");
    let content = ref([]);
    let errors = ref([]);
    let loading = ref(true);
    onBeforeMount(async () => {
      try {
        const data = await ContentService.getModelLibraryContent();
        errors.value = data.errors;
        content.value = ContentService.flattenAllItemsResponse(data);
        loading.value = false;
      } catch (e) {
        console.log("error" + e);
      }
    });
    onMounted(async () => {
      //Fetch info needed for Whitelabeling Vertification
      const instance = getCurrentInstance();
      const usersOrganizationID = kc.idTokenParsed.oroid;
      const whiteLableledPartners =
        WhitelabelService.getWhiteLabeledParternerIDs();
      if (whiteLableledPartners.includes(usersOrganizationID)) {
        //Fetch info needed for whitelabelelbing
        const whitelabelData =
          await WhitelabelService.getWhiteLabeledParterners();
        const partnersThemeData = whitelabelData.data.whitelabelPartners.find(
          (p) => p.organizationID == usersOrganizationID
        );
        let partnersTheme = {
          organizationID: partnersThemeData.organizationID,
          primaryColor: partnersThemeData.primaryColor,
          secondaryColor: partnersThemeData.secondaryColor,
          primaryLogoUrl: partnersThemeData.primaryLogo.url,
        };
        var bodyStyles = document.body.style;
        var logo = document.getElementById("top_logo");
        //Apply Their Theme
        WhitelabelService.updateCurrentTheme(bodyStyles, partnersTheme, logo);
      }
    });
    return {
      content,
      errors,
      loading,
      GridTypes,
    };
  },
};
</script>

<style lang="scss" scoped>
.model-library-grid {
  padding-top: 24px;
  height: 1200px;
}
.second-section {
  padding-top: 64px;
}
.first-section {
  height: 100%;
  vertical-align: middle;
}
.model-card-bullet-point-image {
  width: 40px;
  @media only screen and (max-width: 576px) {
    width: 25px;
  }
}
.model-card {
  p,
  h3 {
    text-align: left;
  }
}
.model-graph-legend {
  display: inline-block;
  p,
  h6 {
    text-align: left;
    color: var(--color__light);
  }
  @media only screen and (max-width: 762px) {
    padding-top: 32px;
  }
}
</style>
