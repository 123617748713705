
import { defineComponent } from "vue";
import Card from "./Card.vue";
import Button from "./Button/Primary.vue";

const LEARN_MORE_LINK = "https://onrampinvest.com/academy";

export default defineComponent({
  components: {
    Card,
    Button,
  },
  methods: {
    goToAcademyTab() {
      window.open(LEARN_MORE_LINK);
    },
  },
});
