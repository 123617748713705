import { FilterCategories } from "@/constants/Filters";

export type ResponseTypes =
  | AllContent
  | Written
  | Audio
  | Video
  | Preview
  | ContentFilters
  | MarketModel
  | AssetAllocation;

export type SchemaTypes = Written | Audio | Video;

export type ContentFilters = {
  [key in FilterCategories]: string[];
};
export interface GraphCmsResponse {
  contentLibrary?: Object;
  data?: ResponseTypes;
  errors?: GraphCmsError;
}

export interface GraphCmsError {
  error: Boolean;
  message?: string;
  code?: string;
}

export interface AllContent {
  written: Array<Preview>;
  audios: Array<Preview>;
  videos: Array<Preview>;
}

export interface Globals {
  id: string;
  title: string;
  featureImage: Asset;
  slug: string;
  isFeatured: boolean;
  __typename?: string;
  subtitle?: string;
  topic: Array<string | null>;
  role: Array<string | null>;
  level?: string;
  format?: string;
  series?: string;
  partner?: string;
}

export interface Written extends Globals {
  richText: Object;
  pdfDownload?: Asset;
  backgroundImage?: Asset;
  showRelated: boolean;
  summary?: Object;
  author?: string;
}

export interface Model extends Globals {
  portfolioId: string;
  factSheetPdf?: Asset;
  richText?: Object;
  contactForm?: string;
}

export interface Video extends Globals {
  /**
   * @TODO confirm if videoURL should be a url (vimeo) or an asset (GraphCMS)
   */
  videoUrl: string;
  showRelated: boolean;
  richText?: Object;
}

export interface Audio extends Globals {
  audio: Asset;
  richText?: Object;
  showRelated: boolean;
}
export interface Preview {
  id: string;
  title: string;
  slug: string;
  topic?: Array<string>;
  level?: string;
  format?: string;
  role?: Array<string>;
  partner?: string;
  series?: string;
  __typename?: string;
  featureImage: Asset;
}

export interface Asset {
  id: string;
  url: string;
}

export enum GridTypes {
  ModelLibrary = "ModelLibrary",
  ContentLibrary = "ContentLibrary",
  ModelLibaryDetailView = "ModelLibraryDetailView",
}

export interface AssetAllocation {
  allocation_weight: Number;
  asset_symbol: string;
}

export interface MarketModel {
  id: string;
  title: string;
  subtitle: string;
  portfolioID?: Number;
  featureImage: {
    id: string;
    url: string;
  };
  factSheetPdf?: {
    url: string;
  };
  richText: {
    html: string;
  };
  slug: string;
  __typename?: string;
  formTitle?: string;
  formSubtitle?: string;
  formReceiptentsEmailAddress?: string;
  formOutgoingEmailSubjectLine?: string;
  partner: string;
  modelDetailsTable?: {
    html: string;
  };
  formUrl?: string;
}
