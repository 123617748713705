<template>
  <div class="container">
    <div class="row mb-4">
      <div class="col-6 input">
        <input
          v-model="searchQuery"
          type="search"
          class="form-control"
          placeholder="Search ..."
        />
      </div>
      <div class="col-6 text-end">
        <a>
          <Button text="Export" @click="downloadFile()" />
        </a>
      </div>
    </div>
  </div>
  <table id="tableComponent" class="table-striped">
    <thead>
      <tr>
        <th
          v-for="(col, index) in columnTitles"
          :key="col"
          @click="sortTable(index)"
        >
          {{ col }}
          <div
            v-if="col == sortColumn"
            class="arrow"
            :class="ascending ? 'arrow_up' : 'arrow_down'"
          />
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="row in filterTable()" :key="row">
        <td v-for="col in getColumnNames()" :key="col">
          <div v-html="prettyPrint(col, row[col])" />
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { computed, ref } from "vue";
import Button from "../components/Button/Secondary.vue";
import FileDownload from "../services/download";

export default {
  name: "TableComponent",
  components: {
    Button,
  },
  props: {
    datarows: { type: Array },
    columnTitles: { type: Array },
    prettyPrint: { type: Function },
  },
  methods: {
    downloadFile() {
      FileDownload.downloadFactcards(this.filterTable());
    },
  },
  setup(props) {
    let rows = ref(props.datarows);
    let searchQuery = ref("");
    var ascending = ref(false);
    var sortColumn = ref("rank");
    //Our search hits against these two columns, name and symbol.
    const filterTable = () => {
      return rows.value.filter(
        (row) =>
          row.name.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
          row.symbol.toLowerCase().includes(searchQuery.value.toLowerCase())
      );
    };
    //I sort the the table based the column clicked.
    const sortTable = (index) => {
      const col = getColumnNames()[index];
      if (sortColumn.value === props.columnTitles[index]) {
        ascending.value = !ascending.value;
      } else {
        ascending.value = true;
        sortColumn.value = props.columnTitles[index];
      }
      rows.value.sort(function (a, b) {
        // Sorting as the strings they are
        if (col == "symbol" || col == "name") {
          if (a[col] > b[col]) {
            return ascending.value ? 1 : -1;
          } else if (a[col] < b[col]) {
            return ascending.value ? -1 : 1;
          }
        } else {
          // Converting the strings to a float to sort
          if (parseFloat(a[col]) > parseFloat(b[col])) {
            return ascending.value ? 1 : -1;
          } else if (parseFloat(a[col]) < parseFloat(b[col])) {
            return ascending.value ? -1 : 1;
          }
        }
        return 0;
      });
    };
    //The column names passed as a prop are vanity names and not the real names used to reference the data. This function gets the real column names.
    const getColumnNames = () => {
      if (rows.value.length == 0) {
        return [];
      }
      return Object.keys(rows.value[0]);
    };

    return {
      ascending,
      filterTable,
      searchQuery,
      sortColumn,
      sortTable,
      getColumnNames,
    };
  },
};
</script>

<style lang="scss" scoped>
.arrow_down {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB8AAAAaCAYAAABPY4eKAAAAAXNSR0IArs4c6QAAAvlJREFUSA29Vk1PGlEUHQaiiewslpUJiyYs2yb9AyRuJGm7c0VJoFXSX9A0sSZN04ULF12YEBQDhMCuSZOm1FhTiLY2Rky0QPlQBLRUsICoIN/0PCsGyox26NC3eTNn3r3n3TvnvvsE1PkwGo3yUqkkEQqFgw2Mz7lWqwng7ztN06mxsTEv8U0Aam5u7r5EInkplUol/f391wAJCc7nEAgE9Uwmkzo4OPiJMa1Wq6cFs7Ozt0H6RqlUDmJXfPIx+qrX69Ti4mIyHA5r6Wq1egND+j+IyW6QAUoul18XiUTDNHaSyGazKcZtdgk8wqhUKh9o/OMvsVgsfHJy0iWqVrcQNRUMBnd6enqc9MjISAmRP3e73T9al3XnbWNjIw2+KY1Gc3imsNHR0YV4PP5+d3e32h3K316TySQFoX2WyWR2glzIO5fLTSD6IElLNwbqnFpbWyO/96lCoai0cZjN5kfYQAYi5H34fL6cxWIZbya9iJyAhULBHAqFVlMpfsV/fHxMeb3er+Vy+VUzeduzwWC45XA4dlD/vEXvdDrj8DvURsYEWK3WF4FA4JQP9mg0WrHZbEYmnpa0NxYgPVObm5teiLABdTQT8a6vrwdRWhOcHMzMzCiXlpb2/yV6qDttMpkeshEzRk4Wo/bfoe4X9vb2amzGl+HoXNT29vZqsVi0sK1jJScG+Xx+HGkL4Tew2TPi5zUdQQt9otPpuBk3e0TaHmMDh1zS7/f780S0zX6Yni+NnBj09fUZUfvudDrNZN+GkQbl8Xi8RLRtHzsB9Hr9nfn5+SjSeWUCXC7XPq5kw53wsNogjZNohYXL2EljstvtrAL70/mVaW8Y4OidRO1/gwgbUMvcqGmcDc9aPvD1gnTeQ+0nmaInokRj0nHh+uvIiVOtVvt2a2vLv7Ky0tL3cRTXIcpPAwMDpq6R4/JXE4vFQ5FI5CN+QTaRSFCYc8vLy1l0rge4ARe5kJ/d27kYkLXoy2Jo4C7K8CZOsEBvb+9rlUp1xNXPL7v3IDwxvPD6AAAAAElFTkSuQmCC");
}
.arrow_up {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAaCAYAAACgoey0AAAAAXNSR0IArs4c6QAAAwpJREFUSA21Vt1PUmEYP4dvkQ8JFMwtBRocWAkDbiqXrUWXzU1rrTt0bdVqXbb1tbW16C9IBUSmm27cODdneoXjputa6069qwuW6IIBIdLvdaF4OAcOiGeDc87zPs/vd57P96WpFq7p6enbGo1mjKZpeTabjU1MTCRagGnOZHFxcXxtbe1XKpUq7+zslJeXl//Mz8+Hy+Uy3RxSE9qTk5M3otFooVQqgef4Wl9f343FYoEmoISrxuNxFX5f9vb2jhn/PxUKhfLS0tIPfFifUESRUMV8Pv/M6XReRm5rTGQyGeXxeGxYe1ezeBpBOBx2rKysbO7v79d4Wy3Y2Nj4GQqFbgnhaugxwiuGJx99Pp9FLBbXxYTXvTqd7v3MzIy6riIWGxJnMpl7AwMD14xGYyMsSq1WUyQdUqn0eSPlusQIsbGrq+vl4OCgvhFQZd1utyv1en0gEolcqsi47nWJlUrlG5fLZVcoFFy2nDKSDpIWlUoVTCQSEk4lCHmJMZ2GTCbTiMVikfIZ88l7enoos9l8dXt7+z6fDicxSJUokqDX6xXcl2wCROoc0vQCWL3sNfLOSdzR0fHY4XC4tVotl40gmVwup9xuN4OQv+UyqCFGH9rg7SOGYVRcBs3IEG4J0nVnamrqOtvuBDGGgQg9+wHFcVEi4a0LNkbdd6TrPKo8ODc311mteIIYjT/a398/jK+s1jnVM0kXoufCFvq0GuiIGEVgQIhfoygM1QrteEa9dAL7ITiYCt4RMabOK5AyKKzKWtvupLcRciu8D5J0EuDDPyT/Snd39yh6VtY2NhYQSR9G79Ds7OxdskRjEyAufvb7/cPoO5Z6e1+xtVKrq6vfcFzyi/A3ZrPZ3GdNSlwgo5ekE4X2RIQGf2C1WlufFE0GBeGWYQ8YERWLxQtnUVB830MKLZfL9RHir8lkssCn2G751tZWEWe03zTKm15YWPiEiXXTYDB0Ig/t7yd8PRws4EicwWHxO4jHD8/C5HiTTqd1BwcHFozKU89origB+y/kmzgYpgOBQP4fGmUiZmJ+WNgAAAAASUVORK5CYII=");
}

.arrow {
  float: right;
  width: 12px;
  height: 15px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position-y: bottom;
}
.disabled {
  display: none !important;
}
table th:hover {
  background: rgba(97, 224, 130, 0.721);
}

table tr:hover {
  background: rgb(185, 234, 188);
}
input {
  display: block;
  height: 48px;
  width: 400px;
  padding: 10px 45px;
  background: white url("../assets/search-icon.svg") no-repeat 15px center;
  background-size: 15px 15px;
  font-size: 16px;
  border: 2px !important;
  border-color: var(--color__grayscale--dark);
  border-radius: 19px;
  box-shadow: rgba(50, 50, 93, 0.25) 1px 1px 1px 1px,
    var(--color__grayscale--dark) 1px 1px 1px 1px;
}
table {
  border: 1px solid #ddd;
  border-collapse: separate;
  border-left: 0;
  border-radius: 4px;
  border-spacing: 0px;
}

thead {
  display: table-header-group;
  vertical-align: middle;
  border-color: inherit;
  border-collapse: separate;
  color: var(--color__grayscale--dark);
}
tr {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
  border-collapse: collapse;
}
th,
td {
  padding: 5px 4px 6px 4px;
  text-align: left;
  vertical-align: top;
  border-top: 1px solid #ddd;
}
thead:first-child tr:first-child th:first-child,
tbody:first-child tr:first-child td:first-child {
  border-radius: 4px 0 0 0;
}
thead:last-child tr:last-child th:first-child,
tbody:last-child tr:last-child td:first-child {
  border-radius: 0 0 0 4px;
}
Button:hover {
  box-shadow: 0 0 14px 1px var(--color__primary) !important;
  border: none !important;
}
</style>
