export const COLOR_ARRAY_FOR_GRAPHS = [
  "#0C7787",
  "#15557D",
  "#2E396B",
  "#1C8A7D",
  "#5286B3",
  "#6A9ABD",
  "#586B8A",
  "#173F4F",
  "#6FADA2",
  "#5D918A",
  "#19456E",
  "#052E2D",
  "#043D5C",
  "#17354A",
  "#152E29",
  "#114F14",
  "#108556",
  "#3C493F",
];
export const COLOR_ARRAY_COUNT = COLOR_ARRAY_FOR_GRAPHS.length;
