import axios, { AxiosResponse, AxiosError } from "axios";
import type { GraphCmsResponse } from "@/types/GraphCMS";
import WhiteLabelTheme from "@/types/WhiteLabel";
import { getEnvironmentVariable } from "@/utils/environment-variables";

const baseApiUrl = getEnvironmentVariable(
  "VUE_APP_ACADEMY_BACKEND_SERVICE_URI"
);
const apiUrl = `${baseApiUrl}/whitelabel`;

export default class WhiteLabelService {
  public static updateCurrentTheme(
    bodyStyles: Document["body"]["style"],
    themeToApply: WhiteLabelTheme,
    logo: HTMLElement
  ) {
    bodyStyles.setProperty("--color__primary", themeToApply.primaryColor);
    bodyStyles.setProperty("--color__secondary", themeToApply.secondaryColor);
    logo.setAttribute("transition", "all .3s ease-in-out");
    logo.setAttribute("src", themeToApply.primaryLogoUrl);
    logo.setAttribute("height", "32px");
  }

  public static getWhiteLabeledParternerIDs(): Array<Number> {
    /**
     * @returns a list of oganization id's that have whitelabeling enabled. This list can be referenced
     * to see if there is a need to maintain the whitelabel service for the
     * duriation of a users session.This should could as an env varaible value. It's purpose is to prevent
     * non-whitelabed users from going thru the trouble of fetching the whitelabled payload.
     */

    //  if (sessionStorage.getItem("wlpids.ora") == null) {
    //   return await axios
    //     .get(`${apiUrl}/get-ids`)
    //     .then((res: AxiosResponse): GraphCmsResponse => {
    //       return res.data;
    //     })
    //     .catch((err: AxiosError): GraphCmsResponse => {
    //       return this.checkForGraphCmsError(err);
    //     });
    // } else {
    //   return JSON.parse(sessionStorage.getItem("wlpids.ora")!);
    // }

    // We have no whitelabeled partners, so muting this call. Return [759] to test
    // with the onramp org.
    return [];
  }

  public static async getWhiteLabeledParterners(): Promise<GraphCmsResponse> {
    /**
     * @returns all whitelabel partners, with the uid as their oraganization id
     * along with their branded preferences.
     */
    if (sessionStorage.getItem("wlp.ora") == null) {
      return await axios
        .get(`${apiUrl}/get-all`)
        .then((res: AxiosResponse): GraphCmsResponse => {
          return res.data;
        })
        .catch((err: AxiosError): GraphCmsResponse => {
          return this.checkForGraphCmsError(err);
        });
    } else {
      return JSON.parse(sessionStorage.getItem("wlp.ora")!);
    }
  }

  public static checkForGraphCmsError(error: AxiosError): GraphCmsResponse {
    /**
     * @param data - an Axios api error
     *
     * @limitation - returns detail on first error message only
     *
     * @returns - GraphCMS Error
     */
    return {
      errors: {
        error: true,
        message: error.message,
        code: error.code,
      },
    };
  }
}
