<template>
  <section>
    <div class="container">
      <div
        class="row"
        :class="collapsed ? 'search-container' : 'search-and-filter-container'"
      >
        <div class="col-md-10">
          <input
            v-model="searchQuery"
            type="search"
            class="search-bar form-control"
            placeholder="Search ..."
          />
        </div>
        <div class="col-md-2">
          <ButtonWithIcon
            class="filtersButton"
            :text="'Filters'"
            :min-width="'100%'"
            @click="toggleFilters"
          />
        </div>
        <div v-if="!collapsed" class="col-12 border-top">
          <div class="row">
            <div
              v-for="filterGroup in filterGroups"
              :key="filterGroup"
              class="col-md-2 mt-3"
            >
              <div class="dropdown">
                <button
                  class="btn btn-secondary dropdown-toggle dropdownMenu"
                  type="button"
                  id="dropdownMenuButton"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {{ filterGroup }}
                </button>
                <ul class="dropdown-menu">
                  <li>
                    <label
                      v-for="filter in filters[filterGroup]"
                      :key="filter"
                      class="dropdown-item"
                      ><input
                        type="checkbox"
                        :value="filter"
                        v-model="selectedFilters"
                      />
                      {{ filter }}</label
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="d-flex flex-row flex-wrap justify-content-space-between align-items-baseline mb-1 chickletMargin"
      >
        <FilterChicletSet
          v-for="([key, val], i) in Object.entries(selectedFilters)"
          :key="i"
          :filter-type="val"
          :filter-value="key"
          @remove-filter="removeFilterViaChicletClick(val)"
        />
        <Label
          v-if="Object.keys(selectedFilters).length > 0"
          class="ms-2"
          :text="'Clear All'"
          :color="'#001864'"
          :cursor="'pointer'"
          :weight="'700'"
          :alignment="'right'"
          @click="resetContentFilters"
        />
      </div>
    </div>
  </section>
  <section class="filterGridSection">
    <FilterGrid
      :content="filterTheGrid()"
      :on-init-show-this-many-items="initialNumberOfTilesShown"
    />
  </section>
</template>

<script>
import { onBeforeMount, ref, computed } from "vue";
import ButtonWithIcon from "../components/Button/Icon.vue";
import FilterChicletSet from "../components/FilterChiclets.vue";
import FilterGrid from "../components/FilterGrid.vue";
import Label from "../components/Typographical/Label.vue";

export default {
  name: "FilterGridWithSearch",
  components: {
    ButtonWithIcon,
    FilterChicletSet,
    FilterGrid,
    Label,
  },
  props: {
    initialNumberOfTilesShown: Number,
    data: { type: Array },
    filterGroups: { type: Array },
    filters: { type: Object },
  },
  setup(props) {
    let gridContent = ref(props.data);
    let searchQuery = ref("");
    let selectedFilters = ref([]);
    const collapsed = ref(true);
    const toggleFilters = () => {
      collapsed.value = !collapsed.value;
    };
    const resetContentFilters = () => {
      selectedFilters.value = [];
      toggleFilters();
    };
    const removeFilterViaChicletClick = (filterToRemove) => {
      selectedFilters.value = selectedFilters.value.filter((item) => {
        return item != filterToRemove;
      });
    };
    const filterTheGrid = () => {
      const filtersToCheck = selectedFilters.value
        .toString()
        .toLowerCase()
        .split(",");
      const query = searchQuery.value.toLowerCase();
      return gridContent.value.filter((item) => {
        // Search Pattern: {searchQuery And {filter And ..filter.. And filter}}
        const doesFitlerMatch = (q) => {
          if (
            item.topic?.toString().toLowerCase().includes(q) ||
            item.format?.toLowerCase().includes(q) ||
            item.level?.toLowerCase().includes(q) ||
            item.partner?.toLowerCase().includes(q) ||
            item.role?.toString().toLowerCase().includes(q) ||
            item.series?.toLowerCase().includes(q)
          ) {
            return true;
          }
          return false;
        };
        const numberOfFiltersSelected = filtersToCheck.length;
        let numberOfFiltersMatched = 0;
        let identfiedBySearch = false;
        if (
          item.title.toLowerCase().includes(query) ||
          doesFitlerMatch(query)
        ) {
          identfiedBySearch = true;
        }
        filtersToCheck.forEach((filters) => {
          const filter = filters.replaceAll(" ", "_");
          if (doesFitlerMatch(filter)) {
            numberOfFiltersMatched += 1;
          }
        });
        return (
          identfiedBySearch && numberOfFiltersSelected == numberOfFiltersMatched
        );
      });
    };

    return {
      collapsed,
      filterTheGrid,
      gridContent,
      removeFilterViaChicletClick,
      resetContentFilters,
      searchQuery,
      selectedFilters,
      toggleFilters,
    };
  },
};
</script>
<style lang="scss" scoped>
.dropdown-menu label {
  display: block;
}

.search-bar {
  height: var(--height);
  width: 100%;
  background: white url("../assets/search-icon.svg") no-repeat 15px center;
  background-size: 15px 15px;
  padding: 10px;
  border-radius: 40px;
  text-align: left;
  padding-left: 40px;
  margin-top: 25px;
  margin-bottom: 10px;
  border: 2px solid var(--color__grayscale--medium);
}
.search-bar::placeholder {
  text-align: left;
}
.search-container {
  height: 104px;
  display: flex;
  flex-direction: row;
  margin-top: 25px;
  border: 2px solid var(--color__grayscale--light);
  box-sizing: border-box;
  border-radius: 40px;
}
.search-and-filter-container {
  height: 200px;
  display: flex;
  flex-direction: row;
  margin-top: 25px;

  border: 2px solid var(--color__grayscale--light);
  box-sizing: border-box;
  border-radius: 40px;
}
.filtersButton {
  margin-top: 19px;
}
.filtersButton:hover {
  box-shadow: 0 0 14px 1px var(--color__primary) !important;
  border: none;
  color: white !important;
}
.dropdownMenu {
  color: var(--color__secondary);
  background-color: white;
  height: 56px;
  border-radius: 14px;
  width: 100%;
}

.btn-secondary:hover {
  color: var(--color__light) !important;
  background-color: var(--color__secondary) !important;
  box-shadow: 0 0 14px 1px var(--color__primary) !important;
}

.border-top {
  border-top: 4px solid var(--color__grayscale--light);
}
.chickletMargin {
  margin-top: 10px;
}
@media only screen and (max-width: 768px) {
  .chickletMargin {
    margin-top: 25px;
  }
  .search-and-filter-container {
    height: 580px;
  }
  .filterGridSection {
    margin-top: 69px;
  }
}
</style>
