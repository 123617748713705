
import { defineComponent, ref, computed } from "vue";
import Card from "./Card.vue";

export default defineComponent({
  components: {
    Card,
  },
  props: {
    source: { type: String, default: "" },
  },

  setup(props) {
    const showOverlay = ref(true);
    const autoplaySource = computed(() => {
      return props.source + "?autoplay=1&amp;";
    });
    const background = computed(() => {
      return showOverlay.value ? "#131c4f" : "transparent";
    });

    return {
      showOverlay,
      background,
      autoplaySource,
    };
  },
  methods: {
    play() {
      this.showOverlay = false;
    },
  },
});
