import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-269bd486"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-0 page-layout-1" }
const _hoisted_2 = {
  key: 2,
  class: "container"
}
const _hoisted_3 = { class: "row" }
const _hoisted_4 = {
  key: 0,
  class: "loading-section"
}
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { class: "row" }
const _hoisted_7 = { class: "col" }
const _hoisted_8 = { class: "container-fluid px-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AuthNavigation = _resolveComponent("AuthNavigation")!
  const _component_PublicNavigation = _resolveComponent("PublicNavigation")!
  const _component_Loader = _resolveComponent("Loader")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "container-fluid header-background",
      style: _normalizeStyle(_ctx.customCss)
    }, [
      (_ctx.authState && _ctx.authState.authenticated)
        ? (_openBlock(), _createBlock(_component_AuthNavigation, {
            key: 0,
            "dark-mode": _ctx.navbarDarkMode
          }, null, 8, ["dark-mode"]))
        : (_openBlock(), _createBlock(_component_PublicNavigation, { key: 1 })),
      (_ctx.useTitleSection)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _renderSlot(_ctx.$slots, "header", {}, undefined, true)
            ])
          ]))
        : _createCommentVNode("", true)
    ], 4),
    (_ctx.isLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_Loader)
        ]))
      : _createCommentVNode("", true),
    (!_ctx.isLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          (_ctx.useSecondHeader)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "container-fluid second-header-backround",
                style: _normalizeStyle(_ctx.secondSectionCustomCss)
              }, [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, [
                    _renderSlot(_ctx.$slots, "second-header", {}, undefined, true)
                  ])
                ])
              ], 4))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_8, [
            _renderSlot(_ctx.$slots, "default", {}, undefined, true)
          ])
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_Footer)
  ]))
}