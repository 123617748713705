
import { defineComponent, computed } from "vue";

export default defineComponent({
  props: {
    text: { type: String, default: "" },
    textColor: { type: String, default: "var(--color__secondary)" },
    disabledTextColor: {
      type: String,
      default: "var(--color__grayscale--medium)",
    },
    minWidth: { type: String, default: "117px" },
    width: { type: String, default: "" },
    height: { type: String, default: "56px" },
    whitespace: { type: String, default: "nowrap" },
    backgroundColor: { type: String, default: "var(color__light)" },
    disabledBackgroundColor: {
      type: String,
      default: "var(--color__grayscale--dark)",
    },
    hoverOpacity: { type: Number, default: 1 },
    outlined: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    padVertical: { type: String, default: "16px" },
    padHorizontal: { type: String, default: "32px" },
    borderColor: { type: String, default: "var(--color__light)" },
    customClasses:{type: String, default: "base-button"}
  },
  setup(props, context) {
    const emitButtonClick = () => {
      context.emit("button-click");
    };

    const customCss = computed(() => {
      return {
        "--color": props.textColor,
        "--height": props.height,
        "--minWidth": props.minWidth,
        "--width": props.width,
        "--whitespace": props.whitespace,
        "--border": props.outlined ? `2px solid ${props.borderColor}` : "none",
        "--background-color": props.backgroundColor,
        "--padding": `${props.padVertical} ${props.padHorizontal}`,
        "--hover-opacity": !props.disabled ? props.hoverOpacity : 0.5,
        "--disabled-opacity": props.disabled ? 0.5 : 1,
        "--cursor": props.disabled ? "not-allowed" : "pointer",
      };
    });
    return {
      customCss,
      emitButtonClick,
    };
  },
  methods: {},
});
