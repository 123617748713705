
import { defineComponent } from "vue";
import Button from "./Base.vue";

export default defineComponent({
  components: {
    Button,
  },
  props: {
    text: { type: String, default: "" },
    disabled: { type: Boolean, default: false },
  },
});
