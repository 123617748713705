<template>
  <!-- <section class="container-fluid"> -->
    <nav
      class="navbar navbar-wrapper navbar-expand-lg pt-4 nav-bar-text-for-light-bgs bg-white"
    >
      <div class="container">
        <div class="navbar-nav">
          <img
            id="top_logo"
            v-if="!darkMode"
            alt="Onramp logo"
            src="@/assets/onramp-logo-text.png"
          />
          <img
            id="top_logo"
            v-if="darkMode"
            alt="Onramp logo"
            src="@/assets/onramp-logo-text.png"
          />
        </div>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <img
            src="@/assets/navigation-hamburger.svg"
            class="navbar-toggler-icon"
          />
        </button>
        <div id="navbarSupportedContent" class="collapse navbar-collapse">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            <li v-if="authState && authState.authenticated" class="nav-item">
              <a class="nav-link">
                <router-link to="/content-library" class="nav-item nav-link">
                  Content Library
                </router-link>
              </a>
            </li>
            <li v-if="authState && authState.authenticated" class="nav-item">
              <a class="nav-link">
                <router-link to="/model-library" class="nav-item nav-link">
                  Model Marketplace
                </router-link>
              </a>
            </li>
            <li v-if="authState && authState.authenticated" class="nav-item">
              <a class="nav-link">
                <router-link to="/fact-cards" class="nav-item nav-link">
                  Fact Cards
                </router-link>
              </a>
            </li>
            <li v-if="authState && authState.authenticated" class="nav-item">
              <a class="nav-link">
                <router-link
                  to="/tools/custom-strategy-dashboard"
                  class="nav-item nav-link"
                >
                  Tools
                </router-link>
              </a>
            </li>
          </ul>
          <div>
            <button
              v-if="authState && authState.authenticated"
              id="logout-button ms-1"
              class="btn btn-outline-success logout-button m-2"
              @click="advisor"
            >
              Advisor
            </button>
            <button
              v-if="authState && authState.authenticated"
              id="logout-button ms-1"
              class="btn btn-outline-success logout-button"
              @click="logout"
            >
              Logout
            </button>
          </div>
        </div>
      </div>
    </nav>
  <!-- </section> -->
</template>

<script type="ts">
import {OurExternalTools} from "../constants/AcademyTools";
import { inject } from "vue";
import { getEnvironmentVariable } from "../utils/environment-variables";
const VUE_APP_OKTA_ISSUER =  getEnvironmentVariable("VUE_APP_OKTA_ISSUER");
const VUE_APP_LOGOUT_REDIRECT_URI =  getEnvironmentVariable("VUE_APP_LOGOUT_REDIRECT_URI");
const VUE_APP_ADVISOR_LINK =  getEnvironmentVariable("VUE_APP_ADVISOR_LINK");
export default {
  name: "NavigationBar",
  props: {
    darkMode: Boolean,
    externalTools: {
       default: OurExternalTools,
    }
  },
  methods: {
    async logout() {
      await this.authState.logout();
      sessionStorage.clear()
    },
    async advisor() {
      window.open(VUE_APP_ADVISOR_LINK, '_blank');
    }
  },
  setup() {
    const kc = inject("$kc");
    return {
      authState: kc
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/components/navigation.scss";
#top_logo{
  height: 45px;
}
.bg-white {
  background-color: white;
  padding-left: 0 !important; 
  padding-right: 0 !important;
  margin-left: 0 !important; 
  margin-right: 0 !important;
  --bs-gutter-x: 0rem;
}
.nav-bar-text-for-light-bgs {
  a.nav-link {
    color: var(--color__secondary);
    font-size: 16px;
    font-weight: 500;
  }
  a.nav-link :hover {
    color: var(--color__primary);
  }
}
.nav-bar-text-for-dark-bgs {
  a.nav-link {
    color: var(--color__light);
  }
  a.nav-link :hover {
    color: var(--color__primary);
  }
}

.logout-button {
  width: 85px;
  height: 40px;
  color: var(--color__secondary);
  font-weight: 700;
  font-size: 14px;
  white-space: nowrap;
  border: 1px solid var(--color__secondary);
  background: var(--color__light);
  border-radius: 50px;
}
.logout-button:hover {
  background: var(--color__secondary);
  color: var(--color__light);
  border: 1px solid var(--color__secondary);
}

.navbar-wrapper {
    // position: absolute;
    top: 0;
    right: 0;
    width: 100% !important;
    left: 0;
    z-index: 20;
}

/* Flip around the padding for proper display in narrow viewports */

</style>
