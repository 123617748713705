
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    filterType: { type: String, default: () => "" },
    filterValue: { type: String, default: () => "" },
  },
  setup(props, context) {
    const triggerRemoval = () => {
      context.emit("remove-filter", {
        filterType: props.filterType,
        filterValue: props.filterValue,
      });
    };
    return {
      triggerRemoval,
    };
  },
});
