
import { defineComponent, ref } from "vue";

const PRIVACY_POLICY = "privacy-policy.pdf";
const TOS = "platform-services-agreement.pdf";
const ONRAMP_HOMEPAGE = "https://onrampinvest.com";
const ONRAMP_TWITTER = "https://twitter.com/Onrampinvest";
const ONRAMP_LINKEDIN = "https://www.linkedin.com/company/onrampinvest";

export default defineComponent({
  setup() {
    const privacyPolicy = ref(PRIVACY_POLICY);
    const tos = ref(TOS);
    const homePage = ref(ONRAMP_HOMEPAGE);
    const twitter = ref(ONRAMP_TWITTER);
    const linkedin = ref(ONRAMP_LINKEDIN);

    return {
      privacyPolicy,
      tos,
      homePage,
      twitter,
      linkedin,
    };
  },
  methods: {
    goToLink(link: string) {
      window.open(link);
    },
  },
});
