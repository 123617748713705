import axios, { AxiosResponse, AxiosError } from "axios";
import type {
  GraphCmsResponse,
  AllContent,
  ResponseTypes,
} from "@/types/GraphCMS";
import { getEnvironmentVariable } from "@/utils/environment-variables";

const baseApiUrl = getEnvironmentVariable(
  "VUE_APP_ACADEMY_BACKEND_SERVICE_URI"
);
const apiUrl = `${baseApiUrl}/content`;

export default class ContentService {
  public static async getFactCards(): Promise<GraphCmsResponse> {
    /**
     * @returns top 30 fact cards with fields
     * showing meta data and links to pdfs in graphcms
     */
    return await axios
      .get(`${apiUrl}/fact-cards`)
      .then((res: AxiosResponse): GraphCmsResponse => {
        return res.data;
      })
      .catch((err: AxiosError): GraphCmsResponse => {
        return this.checkForGraphCmsError(err);
      });
  }
  public static async getModelLibraryContent(): Promise<GraphCmsResponse> {
    /**
     * @returns all items across all GraphCMS schemas with fields
     * required for previewing, searching, and filtering
     */
    if (sessionStorage.getItem("marketModels.ora") == null) {
      return await axios
        .get(`${apiUrl}/model-library`)
        .then((res: AxiosResponse): GraphCmsResponse => {
          sessionStorage.setItem("marketModels.ora", JSON.stringify(res.data));
          return res.data;
        })
        .catch((err: AxiosError): GraphCmsResponse => {
          return this.checkForGraphCmsError(err);
        });
    } else {
      return JSON.parse(sessionStorage.getItem("marketModels.ora")!);
    }
  }
  public static async getPartnersModelLibaryContent(
    partnersName: String
  ): Promise<GraphCmsResponse> {
    /**
     * @returns all items across all GraphCMS schemas with fields
     * required for previewing, searching, and filtering
     */
    return await axios
      .get(`${apiUrl}/model-library/${partnersName}`)
      .then((res: AxiosResponse): GraphCmsResponse => {
        return res.data;
      })
      .catch((err: AxiosError): GraphCmsResponse => {
        return this.checkForGraphCmsError(err);
      });
  }

  public static async getModelLibraryIndividualModelBySlug(
    slug: String
  ): Promise<GraphCmsResponse> {
    return await axios
      .get(`${apiUrl}/model-library-entry/${slug}`)
      .then((res: AxiosResponse): GraphCmsResponse => {
        return res.data;
      })
      .catch((err: AxiosError): GraphCmsResponse => {
        return this.checkForGraphCmsError(err);
      });
  }

  public static async getModelLibraryAssetAllocationByModelID(
    model_ID: String
  ): Promise<GraphCmsResponse> {
    return await axios
      .get(`${apiUrl}/model-library-entry/asset-allocations/${model_ID}`)
      .then((res: AxiosResponse): GraphCmsResponse => {
        return res.data;
      })
      .catch((err: AxiosError): GraphCmsResponse => {
        return this.checkForGraphCmsError(err);
      });
  }

  public static async getAllEntriesForPreview(): Promise<GraphCmsResponse> {
    /**
     * @returns all items across all GraphCMS schemas with fields
     * required for previewing, searching, and filtering
     */
    if (sessionStorage.getItem("gridData.ora") == null) {
      return await axios
        .get(`${apiUrl}/preview`)
        .then((res: AxiosResponse): GraphCmsResponse => {
          sessionStorage.setItem("gridData.ora", JSON.stringify(res.data));
          return res.data;
        })
        .catch((err: AxiosError): GraphCmsResponse => {
          return this.checkForGraphCmsError(err);
        });
    } else {
      return JSON.parse(sessionStorage.getItem("gridData.ora")!);
    }
  }

  public static async getFeaturedEntries(): Promise<GraphCmsResponse> {
    /**
     * @returns Preview for all featured entries
     */
    if (sessionStorage.getItem("bannerData.ora") == null) {
      return await axios
        .get(`${apiUrl}/featured-preview`)
        .then((res: AxiosResponse): GraphCmsResponse => {
          sessionStorage.setItem("bannerData.ora", JSON.stringify(res.data));
          return res.data;
        })
        .catch((err: AxiosError): GraphCmsResponse => {
          return this.checkForGraphCmsError(err);
        });
    } else {
      return JSON.parse(sessionStorage.getItem("bannerData.ora")!);
    }
  }
  public static async searchAllSchemasOnTitle(
    searchFilter: String
  ): Promise<GraphCmsResponse> {
    /**
     * @param searchFilter = string search each schema by on its title field
     * @returns - content entries whose title contains the searchFilter param
     */
    const data = {
      input: searchFilter.length > 0 ? searchFilter : "",
    };

    return await axios
      .post(`${apiUrl}/preview-search/all-titles`, data)
      .then((res: AxiosResponse): GraphCmsResponse => {
        return res.data;
      })
      .catch((err: AxiosError): GraphCmsResponse => {
        return this.checkForGraphCmsError(err);
      });
  }

  public static async getContentlLibraryIndividualEntryBySlug(
    slug: String
  ): Promise<GraphCmsResponse> {
    return await axios
      .get(`${apiUrl}/content-library-entry/${slug}`)
      .then((res: AxiosResponse): GraphCmsResponse => {
        return res.data;
      })
      .catch((err: AxiosError): GraphCmsResponse => {
        return this.checkForGraphCmsError(err);
      });
  }

  public static async getFilterCategories(): Promise<GraphCmsResponse> {
    /**
     * @returns - Object whose keys are filter categories and values are
     * arrays of that filter category's values
     */
    if (sessionStorage.getItem("filterData.ora") == null) {
      return await axios
        .get(`${apiUrl}/filter-categories`)
        .then((res: AxiosResponse): GraphCmsResponse => {
          sessionStorage.setItem("filterData.ora", JSON.stringify(res.data));
          return res.data;
        })
        .catch((err: AxiosError): GraphCmsResponse => {
          return this.checkForGraphCmsError(err);
        });
    } else {
      return JSON.parse(sessionStorage.getItem("filterData.ora")!);
    }
  }
  public static checkForGraphCmsError(error: AxiosError): GraphCmsResponse {
    /**
     * @param data - an Axios api error
     *
     * @limitation - returns detail on first error message only
     *
     * @returns - GraphCMS Error
     */
    return {
      errors: {
        error: true,
        message: error.message,
        code: error.code,
      },
    };
  }
  public static flattenAllItemsResponse(
    data: AllContent
  ): Array<ResponseTypes> {
    /**
     * @param data - response data of the all content query
     * @returns flattened version of the response
     */
    const allContent: Array<ResponseTypes> = [];

    for (const [type, contentTypeList] of Object.entries(data)) {
      /**
       * @TODO - try to append whole array to all content
       *         to prevent nested iteration
       */
      for (const entry of contentTypeList) {
        // [TODO] - duck tape solution below - Keith is working on something else
        entry.type = type;
        allContent.push(entry);
      }
    }
    return allContent;
  }
}
