<template>
  <div class="contanier cardHomePageFeature">
    <div
      id="featuredArticleCarousel"
      class="carousel slide carousel-fade"
      data-bs-ride="carousel"
      data-bs-interval="3000"
    >
      <div class="carousel-inner curve-me">
        <div
          v-for="(item, index) in content"
          :key="item.id"
          :class="{ 'carousel-item': true, active: index == 0 }"
        >
          <router-link
            :to="{
              name: 'ContentLibraryDetailView',
              params: { slug: item.slug },
            }"
          >
            <FeaturedSliderSlide
              :img-source="item.featureImage.url"
              :line1="item.title"
              :line2="item.slug"
            />
          </router-link>
        </div>
      </div>
      <div class="carousel-indicators">
        <button
          v-for="(item, index) in content"
          :key="item.id"
          :data-bs-slide-to="index"
          data-bs-target="#featuredArticleCarousel"
          type="button"
          :aria-current="index == 0"
          :class="{ active: index == 0 }"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { ref, onBeforeMount } from "vue";
import FeaturedSliderSlide from "../components/FeaturedSliderSlide.vue";
import ContentService from "../services/content";

export default {
  name: "FeaturedSlider",
  components: {
    FeaturedSliderSlide,
  },
  props: {
    content: Array,
  },
};
</script>

<style lang="scss" scoped>
.carousel-indicators button,
.carousel-indicators button.active {
  width: 1rem;
  height: 1rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  border-radius: 50%;
  border: 0;
  background: transparent;
}

.carousel-indicators button {
  background: rgba(255, 255, 255, 0.39);
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  opacity: 0.8;
}

.carousel-indicators button.active {
  background: var(--color__primary);
}
.curve-me {
  border-radius: 25px;
}
</style>
