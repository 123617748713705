<template>
  <PageLayout
    :background-img-type="'modelLibrary'"
    :use-title-section="true"
    :header-text-color="'var(--color__light)'"
    :navbar-dark-mode="false"
    :loading="loading"
  >
    <template #header>
      <div class="row"><br/><br/><br/><br/></div>
    </template>
    <section class="py-2">
      <div class="container">
        <div class="row">
          <div class="align-items-center mt-5 mb-5 col-8">
            <h1 class="header">
              {{ content.title }}
            </h1>
            <h2 class="body">
              {{ content.subtitle }}
            </h2>
          </div>
          <div class="col-4 align-items-center mt-5 justify-content-center ">
            <div
              v-if="requestAccess" 
              class="row my-1"
            >
              <a 
                :href="content.formUrl" 
                target="_blank"
              >
                <Button text="Request Access" />
              </a>
            </div>
            <div
              v-else-if="authState && authState.authenticated"
              class="row my-1"                 
            >
              <a
                target="_blank"
              >
                <Button @click="advisor" text="Invest Now" />
              </a>
            </div>
            <div
              v-else
              class="row my-1"
            >
              <a 
                href="https://onrampinvest.com/book-a-demo/" 
                target="_blank"
              >
                <Button text="Request Access" />
              </a>
            </div>
            <row v-if="content.factSheetPdf?.url" class="row my-1">
              <a 
                :href="content.factSheetPdf?.url" 
                target="_blank"
              >
                <Button text="Download Factsheet" />
              </a>
            </row>
          </div>
        </div>
        <div class="row pl-0 pr-0">
          <div class="col-8">
            <Card
              border-size-radius="0px"
            >
              <div v-html="content.richText.html" />
            </Card>
          </div>
          <div class="col-4">
            <template v-if="showAllocations&& authState && authState.authenticated">
              <div class="row pl-3 pr-3">
                <div class="col text-start">
                  <h4>{{ content.title }}'s Allocations</h4>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <table class="w-100 p-3">
                    <tr>
                      <th class="text-left pr-1">
                        <h6>Symbol</h6>
                      </th>
                      <th
                        class="text-end pl-1 seperate-us"
                      >
                        <h6>Weight</h6>
                      </th>
                    </tr>
                    <tr
                      v-for="item in tableDataSet"
                      :key="item.id"
                    >
                      <td class="small">{{ item.asset_symbol }}</td>
                      <td class="text-end small">
                        {{ item.allocation_weight }}%
                      </td>
                    </tr>
                  </table>
                </div>
                <div class="col">
                  <Doughnut
                    :chart-data="chartJSData"
                    :chart-options="chartJSDisyplayOptions"
                  />
                </div>
              </div>
            </template>
            <div v-html="content.modelDetailsTable.html" />
          </div>
        </div>
      </div>
      <!-- This email field will come from graphCMS, and will be realtive to the model provider.  -->
      <template v-if="showEmailForm">
        <FormMarketModelIntake
          :recipients-email-address="
            content.emailFormReceiptentsEmail
          "
          :subject="content.emailFormSubjectLine"
        />
      </template>

      <div class="container">
        <h1 class="text-center mt-5 mb-2">
          {{ String(content.partner) }} Models
        </h1>
        <FilterGrid
          :content="partnersContent"
          :route-to="'ModelLibraryDetailView'"
          :on-init-show-this-many-items="8"
          :outline-my-items="false"
          :hide-load-more-btn-when-btn-is-disabled="true"
        />
      </div>
    </section>
  </PageLayout>
</template>

<script>
import { COLOR_ARRAY_FOR_GRAPHS, COLOR_ARRAY_COUNT } from "../constants/Colors";
import { Doughnut } from "vue-chartjs";
import { ref, onBeforeMount, toRef, inject } from "vue";
import { useRoute } from "vue-router";
import Button from "../components/Button/Tertiary.vue";
import Card from "../components/Card.vue";
import {
    Chart as ChartJS,
    ArcElement,
    Title,
    Tooltip,
    Legend,
    CategoryScale,
} from "chart.js";
ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale);
import ContentService from "../services/content";
import FileDownload from "../services/download";
import FilterGrid from "../components/FilterGrid.vue";
import FormMarketModelIntake from "../components/FormMarketModelIntake.vue";
import PageLayout from "../components/PageLayout.vue";
import { getEnvironmentVariable } from "../utils/environment-variables";
const VUE_APP_ADVISOR_LINK =  getEnvironmentVariable("VUE_APP_ADVISOR_LINK");
export default {
    name: "ModelLibraryDetailView",
    components: {
        Button,
        Card,
        Doughnut,
        FilterGrid,
        FormMarketModelIntake,
        PageLayout,
    },
    setup() {
        const kc = inject("$kc");
        const route = useRoute();
        let chartJSData = ref([]);
        let content = ref([]);
        let downloadModel = ref(null);
        let errors = ref([]);
        let loading = ref(true);
        let partnersContent = ref([]);
        let showAllocations = ref(false);
        let showEmailForm = ref(false);
        let requestAccess = ref(false);
        let tableDataSet = ref([]);
        onBeforeMount(async () => {
            try {
                //Fetch Data
                const data =
                    await ContentService.getModelLibraryIndividualModelBySlug(
                        route.params.slug
                    );

                if (data.portfolioID != null && data.portfolioID != "") {
                    showAllocations.value = true;
                    const model =
                        await ContentService.getModelLibraryAssetAllocationByModelID(
                            data.portfolioID
                        );
                    //Organize Data for ChartJS
                    let dataBuffer = [];
                    let colorBuffer = [];
                    let nameBuffer = [];
                    for (let [
                        index,
                        value,
                    ] of model.asset_allocations.entries()) {
                        dataBuffer.push(Number(value.allocation_weight * 100));
                        colorBuffer.push(
                            COLOR_ARRAY_FOR_GRAPHS[index % COLOR_ARRAY_COUNT]
                        );
                        nameBuffer.push(value.asset_symbol);
                    }
                    chartJSData.value = {
                        cutout: 90,
                        labels: nameBuffer,
                        datasets: [
                            {
                                label: data.subtitle,
                                backgroundColor: colorBuffer,
                                data: dataBuffer,
                            },
                        ],
                    };
                    //Put Data in place for the table in the vue template to populate from
                    for (let [
                        index,
                        value,
                    ] of model.asset_allocations.entries()) {
                        tableDataSet.value.push({
                            asset_symbol: value.asset_symbol,
                            allocation_weight: (
                                value.allocation_weight * 100
                            ).toFixed(2),
                        });
                    }
                    //Datapointer for the download button
                    downloadModel.value = model;
                }
                //Fetch data for the model grid above the footer, which shows other models by them.
                let partnersData = {};
                for (var partnerName of data.partner) {
                    let partnerData =
                        await ContentService.getPartnersModelLibaryContent(
                            partnerName
                        );
                    partnersData = { ...partnersData, ...partnerData };
                }
                errors.value = data.errors;
                content.value = data;
                partnersContent.value =
                    ContentService.flattenAllItemsResponse(partnersData);
                loading.value = false;
            } catch (e) {
                console.log("Model Library DetailView exception: " + e);
                console.log("GraphCMS error: " + errors.value);
            }
            //Prepare intake form, if configured as such in the realated graphcms entry
            if (
                content.value.emailFormReceiptentsEmail != null &&
                content.value.emailFormReceiptentsEmail != ""
            ) {
                showEmailForm.value = true;
            }
            console.log(content)
            if (
              content.value.formUrl != null &&
              content.value.formUrl !="" 
            ) {
              requestAccess.value = true;
            }
            loading.value = false;
        });
        //Format the chart's tooltip to have 2 decimal places and a % symbol.
        const chartJSDisyplayOptions = {
            plugins: {
                tooltip: {
                    callbacks: {
                        label: function (context) {
                            return (
                                context.label +
                                "  " +
                                (
                                    Math.round(context.parsed * 100) / 100
                                ).toFixed(2) +
                                "%"
                            );
                        },
                    },
                },
            },
        };

        return {
            authState: kc,
            chartJSData,
            chartJSDisyplayOptions,
            content,
            downloadModel,
            errors,
            loading,
            partnersContent,
            showAllocations,
            showEmailForm,
            tableDataSet,
            requestAccess
        };
    },
    methods: {
        // formatDate(date) {
        //   date = date.replace("T", ", ");
        //   return date.substr(0, 17);
        // },
        downloadFile() {
            let tabledData = [];
            for (let [
                index,
                value,
            ] of this.downloadModel.asset_allocations.entries()) {
                const row = {
                    Asset: value.asset_symbol,
                    Weight: value.allocation_weight * 100 + "%",
                };
                tabledData.push(row);
            }
            FileDownload.modelLibraryDownload(tabledData, this.content.slug);
        },
        async advisor() {
          window.open(VUE_APP_ADVISOR_LINK, '_blank');
        }
    },
};
</script>
<style lang="scss" scoped>
@import "@/styles/components/preview-tile.scss";

.seperate-us {
    padding-left: 32px;
}
.next-steps {
    color: var(--color__light);
}
::v-deep .img-container {
    text-align: center;
    img {
        max-width: 92%;
        height: auto;
    }
}
td {
    padding: 0 0px;
}
Button:hover {
    border: none !important;
    box-shadow: 0 0 14px 1px var(--color__secondary) !important;
}
.downloadcsv:hover {
    box-shadow: 0 0 14px 1px var(--color__primary) !important;
}
.bottomDownloadFactcard:hover {
    box-shadow: 0 0 14px 1px white !important;
}
.disabled {
    display: none;
}
.col-4{
  padding-bottom: 100%;
  margin-bottom: -100%;
}
</style>
