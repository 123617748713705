import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "d-flex flex-column align-items-center mt-5 mb-5" }
const _hoisted_2 = { class: "d-flex flex-row align-items-center" }
const _hoisted_3 = { class: "pb-3" }
const _hoisted_4 = { class: "container" }
const _hoisted_5 = { class: "text-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_Label = _resolveComponent("Label")!
  const _component_Table = _resolveComponent("Table")!
  const _component_Card = _resolveComponent("Card")!
  const _component_PageLayout = _resolveComponent("PageLayout")!

  return (_openBlock(), _createBlock(_component_PageLayout, {
    loading: $setup.loading,
    "background-img-type": 'primary',
    "use-title-section": true
  }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", null, [
          _createVNode(_component_Header, { text: 'Fact Cards' })
        ]),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_Label, { text: 'Our Analysis, Risk, and Return Profile of the Top Coins and Tokens' })
        ])
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("section", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_Card, null, {
            default: _withCtx(() => [
              _createVNode(_component_Table, {
                datarows: $setup.content.list_of_fact_cards,
                "column-titles": $setup.headers,
                prettyPrint: $setup.prettyPrint
              }, null, 8, ["datarows", "column-titles", "prettyPrint"]),
              _createElementVNode("em", _hoisted_5, "Current as of " + _toDisplayString($setup.content.as_of_date), 1)
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _: 1
  }, 8, ["loading"]))
}