import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-09007aa4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col pb-5 px-5" }
const _hoisted_4 = { class: "container" }
const _hoisted_5 = { class: "row" }
const _hoisted_6 = { class: "col-lg-4 p-5" }
const _hoisted_7 = { class: "col-lg-4 p-5" }
const _hoisted_8 = { class: "col-lg-4 p-5" }
const _hoisted_9 = { class: "academy-testimonials" }
const _hoisted_10 = { class: "container" }
const _hoisted_11 = { class: "row pt-5" }
const _hoisted_12 = { class: "col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CardHomePageLearnMore = _resolveComponent("CardHomePageLearnMore")!
  const _component_CardHomePageFeature = _resolveComponent("CardHomePageFeature")!
  const _component_Carousel = _resolveComponent("Carousel")!
  const _component_PageLayout = _resolveComponent("PageLayout")!

  return (_openBlock(), _createBlock(_component_PageLayout, {
    "background-img-type": 'public',
    "background-color": '#001864',
    "use-title-section": true
  }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_CardHomePageLearnMore)
          ])
        ])
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_CardHomePageFeature, {
              title: "Industry-Leading Research",
              body: "Research from Onramp + our ecosystem of partners arms you with the knowledge you need to move forward confidently."
            })
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_CardHomePageFeature, {
              title: "Real-Time Data",
              body: "Interact with key data points in real time, including returns, volatility, correlations, prices, and trading volumes."
            })
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_CardHomePageFeature, {
              title: "Applicable Tools",
              body: "Model portfolio allocations in our performance tools and gain access to dozens of practice management docs you can apply to your practice."
            })
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("div", _hoisted_12, [
              _createVNode(_component_Carousel, { "items-per-slide": 3 })
            ])
          ])
        ])
      ])
    ]),
    _: 1
  }))
}