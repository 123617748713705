<template>
  <PageLayout
    :background-img-type="'primary'"
    :use-title-section="true"
    :loading="loading"
  >
    <template #header>
      <div class="align-items-center mt-5 mb-5">
        <h1 class="header">
          {{ entry.title }}
        </h1>
        <h2 v-if="entry.subtitle" class="body">
          {{ entry.subtitle }}
        </h2>
        <p v-if="entry.author">by {{ entry.author }}</p>
        <div v-if="entry.summary">
          <h3 class="body text-left py-4">Summary</h3>
          <div v-html="entry.summary.html"></div>
        </div>
        <div class="pb-3" v-if="entry.pdfDownload">
          <div class="row">
            <div class="col m-2 text-start">
              <a :href="entry.pdfDownload.url">
                <Button text="Download PDF" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </template>
    <section class="pb-3">
      <div class="container">
        <div class="row">
          <div v-if="entry.richText" class="col">
            <div v-if="entry.videoUrl">
              <VimeoVideo :source="entry.videoUrl" />
            </div>
            <div v-if="entry.audioContent">
              <audio class="my-3" controls>
                <source :src="entry.audioContent.url" type="audio/mpeg" />
              </audio>
            </div>
            <div v-html="entry.richText.html" />
          </div>
        </div>
      </div>
    </section>
  </PageLayout>
</template>

<script>
import PageLayout from "../components/PageLayout.vue";
import ContentService from "../services/content";
import { ref, onBeforeMount } from "vue";
import { useRoute } from "vue-router";
import VimeoVideo from "../components/VimeoVideo.vue";
import Button from "../components/Button/Secondary.vue";

export default {
  name: "ContentLibraryDetailView",
  components: {
    PageLayout,
    VimeoVideo,
    Button,
  },
  setup() {
    let entry = ref({});
    let error = ref({});
    let loading = ref(true);

    const route = useRoute();

    const getIndividualItemToDisplay = async () => {
      if (route.params.slug) {
        const data =
          await ContentService.getContentlLibraryIndividualEntryBySlug(
            route.params.slug
          );
        if (!data.errors) {
          entry.value = data;
        } else {
          error.value = data.errors;
        }

        loading.value = false;
      }
    };

    onBeforeMount(async () => {
      await getIndividualItemToDisplay();
    });

    return {
      entry,
      error,
      loading,
    };
  },
};
</script>
<style lang="scss">
@import "@/styles/components/preview-tile.scss";
audio {
  width: 100%;
  border-radius: 22px;
  background-color: var(--color__secondary);
}
.img-container {
  padding: 8px;
  display: flex;
  justify-content: center;
  text-align: center;
  img {
    max-width: 92%;
    height: auto;
  }
}
</style>
